import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import ChatMessage from './ChatMessage';
import { ChatContext } from '../context/chatContext';
import { MdSend, MdLightbulbOutline } from 'react-icons/md';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Modal from './Modal';
import Setting from './Setting';
import PromptPerfect from './PromptPerfect';
import FileUpload from './FileUpload';
import { FaPaperclip } from 'react-icons/fa6';
import ChatInput from './ChatInput';

/**
 * A chat view component that displays a list of messages and a form for sending new messages.
 */
const ChatView = () => {
  const messagesEndRef = useRef();
  const langchainSocket = useRef();
  const inputRef = useRef();
  const fileInputRef = useRef();
  const [formValue, setFormValue] = useState('');
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, addMessage, , , setConnectionStatus] = useContext(ChatContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPromptOpen, setModalPromptOpen] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [sessionStarted, setSessionStarted] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [base64File, setBase64File] = useState(null);

  /**
   * Scrolls the chat area to the bottom.
   */
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Adds a new message to the chat.
   *
   * @param {string} newValue - The text of the new message.
   * @param {boolean} [ai=false] - Whether the message was sent by an AI or the user.
   */
  const updateMessage = useCallback(
    (newValue, ai = false, image = '') => {
      const id = Date.now() + Math.floor(Math.random() * 1000000);
      const newMsg = {
        id: id,
        createdAt: Date.now(),
        text: newValue,
        ai: ai,
        image: image,
      };

      addMessage(newMsg);
    },
    [addMessage],
  );

  /**
   * Sends our prompt to our API and get response to our request from openai.
   *
   * @param {Event} e - The submit event of the form.
   */
  const sendMessage = useCallback(
    async (e) => {
      e.preventDefault();
      if (!inputRef.current.value) return;

      let type = 'sendmessage';

      // If we find a file has been uploaded we want to send it to the Vision API on the websocket server.
      if (base64File) {
        type = 'visionCall';
      }

      let message = inputRef.current.value.trim();

      const newMsg = {
        action: type,
        user_input: message,
        image: base64File,
      };

      if (base64File) {
        updateMessage(message, false, base64File);
      } else {
        updateMessage(message, false);
      }

      setFormValue('');
      setLoading(true);
      fileInputRef.current.value = '';

      langchainSocket.current.send(JSON.stringify(newMsg));

      setBase64File(null);
    },
    [base64File, updateMessage],
  );

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // 👇 Get input value
      sendMessage(e);
      inputRef.current.style.height = 'auto';
    }
  };

  const handleChange = (event) => {
    setFormValue(event.target.value);
  };

  const updatePrompt = async () => {
    try {
      setLoading(true);
      console.log('Update prompt');
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const handleUseClicked = () => {
    setFormValue(prompt);
    setModalPromptOpen(false);
  };

  const initialMessage = useCallback(() => {
    console.log('Sending inital message');
    // Send an initial message to the websocket server if the session has just started.
    // The if statement prevents the message being resent when in dev mode when the component loads twice.
    if (!sessionStarted) {
      langchainSocket.current.send(
        JSON.stringify({
          action: 'sendmessage',
          user_input: 'Hi, introduce yourself.',
        }),
      );
    }
  }, [sessionStarted]);

  // Process incoming messages from the websocket server

  const processMessage = useCallback(
    (message) => {
      console.log(message);
      switch (message.action) {
        case 'agentResponse':
          updateMessage(message.output, true);
          setLoading(false);
          break;
        case 'setToken':
          setSessionId(message.session_id);

          if (!sessionStarted) {
            initialMessage();
            setSessionStarted(true);
          }

          break;
        case 'visionResponse':
          updateMessage(message.result, true);
          setLoading(false);

          break;
        case 'imageGeneration':
          updateMessage(message.text, true, message.image);
          setLoading(false);

          break;
        default:
          console.log(message);
          break;
      }
    },
    [initialMessage, updateMessage, sessionStarted],
  );

  const handleRequestToken = useCallback(() => {
    console.log('Requesting token information...');

    langchainSocket.current.send(JSON.stringify({ action: 'gettoken' }));
  }, []);

  /**
   * Scrolls the chat area to the bottom when the messages array is updated.
   */
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  /**
   * Focuses the TextArea input to when the component is first rendered.
   */
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    inputRef.current.style.height = 'auto';
    inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
  }, [formValue]);

  useEffect(() => {
    if (!sessionStarted) {
      console.log('Starting websocket...');
      setConnectionStatus('Connecting');
      langchainSocket.current = new WebSocket(process.env.REACT_APP_WEBSOCKET);

      langchainSocket.current.onopen = (e) => {
        if (langchainSocket.current.readyState === WebSocket.OPEN) {
          console.log(e);
          setConnectionStatus('Connected');

          handleRequestToken();
        }
      };

      langchainSocket.current.onclose = () => {
        setConnectionStatus('Disconnected');
      };

      langchainSocket.current.onerror = (e) => {
        console.log(e);
        setConnectionStatus('Error');
      };
    }
  }, [handleRequestToken, sessionStarted, setConnectionStatus]);

  useEffect(() => {
    langchainSocket.current.onmessage = (message) => {
      console.log(message.data);
      const data = JSON.parse(message.data);
      console.log(`Incoming message: ${data.action}`);
      processMessage(data);
    };
  }, [processMessage, sessionStarted]);

  return (
    <div className="chatview w-full">
      <main className="chatview__chatarea">
        {messages.map((message, index) => (
          <ChatMessage key={index} message={{ ...message }} />
        ))}

        <span ref={messagesEndRef}></span>
      </main>
      <form className="form" onSubmit={sendMessage}>
        <div className="flex items-stretch justify-between w-full">
          <ChatInput
            inputRef={inputRef}
            handleKeyDown={handleKeyDown}
            handleChange={handleChange}
            value={formValue}
          />
          <div className="flex items-center">
            <button type="submit" className="chatview__btn-send" disabled={!formValue}>
              <MdSend size={30} />
            </button>
            <button
              id="tooltip"
              type="button"
              className="chatview__btn-send"
              disabled={!formValue}
              onClick={updatePrompt}
            >
              {loading ? <div className="loading-spinner" /> : <MdLightbulbOutline size={30} />}
            </button>
            <button
              type="button"
              className="chatview__btn-send"
              onClick={() => setModalFileOpen(true)}
            >
              <FaPaperclip size={30} />
            </button>
          </div>
        </div>
        <ReactTooltip
          anchorSelect="tooltip"
          place="top"
          variant="dark"
          content="Help me with this prompt!"
        />
      </form>
      <Modal title="Setting" modalOpen={modalOpen} setModalOpen={setModalOpen}>
        <Setting modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>
      <Modal title="Prompt Perfect" modalOpen={modalPromptOpen} setModalOpen={setModalPromptOpen}>
        <PromptPerfect
          prompt={prompt}
          onChange={setPrompt}
          onCancelClicked={() => setModalPromptOpen(false)}
          onUseClicked={handleUseClicked}
        />
      </Modal>
      <Modal title="File Upload" modalOpen={modalFileOpen} setModalOpen={setModalFileOpen}>
        <FileUpload
          setBase64File={setBase64File}
          setModalFileOpen={setModalFileOpen}
          fileInputRef={fileInputRef}
        />
      </Modal>
    </div>
  );
};

export default ChatView;
